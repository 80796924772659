.audit-questions {
  width: 97%;
  margin-left: 15px;
}

.mr10 {
  margin-right: 10px;
}
.fullGrid {
  width: 100%;
}
.pl0 {
  padding-left: 0;
}

.ml30 {
  margin-left: 30px;
}

.mb20 {
  margin-bottom: 20px;
}

.audit-border-error {
  border-style: solid;
  border-width: thin;
  border-color: red;
}
