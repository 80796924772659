.zoneInformation ul{
    margin: 0;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0;
    max-width: 130px;
}
.zoneInformation ul input[type='checkbox'],
.zoneInformation ul input[type='radio']{
    margin-right: 10px;
}
.zoneInformation strong{
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
    display: block;
    margin-bottom: 8px;
}