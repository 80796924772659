.wrapperAgentDetails{
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    transition: all 0.8s ease-out;
    width: 100%;
}
.wrapperAgentDetails ul, .wrapperAgentDetails h3, .wrapperAgentDetails label{
    padding: 0;
    margin: 0;
}
.wrapperAgentDetails h3{
    line-height: 40px;
    padding: 10px 0;
}
.mt-20{
    margin-top: 20px;
}
.mt10{
    margin-top: 10px;
}

.mr20 {
    margin-right: 20px;
}
.p20{
    padding: 20px;
}