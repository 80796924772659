/*.main_section_car{
     width: 100%;
    background: #ffffff;
    box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    height:430px; 
}*/
.main-box{
    margin:8px 0;
}
h2{
    font-size: 18px;
}
.main-box .lbl{
    font-size: 12px;
    text-align: left;
    width: 30%;
    margin: 0;
    min-width: 100px;
    line-height: 2;
}
/*.main-box .txtbox{
     height: 30px;
    max-width: 70%;
    background: #ffffff;
    margin: 0 0 0 8px;
    padding: 0 8px;
    font-size: 14px; 
}*/
.main-box .select{
    height: 30px;
    width: 70%;
    background: #ffffff;
    padding: 0 8px;
    font-size: 14px;
    border-radius: 4px;
    border:1px solid #ced4da;
}
.main-box .radio_label{
    font-size: 12px;
    margin-right: 8px;
    padding:12px 0;
}
.radio_label .radio-btn{
padding-right: 3px;
}
.main-box :disabled{
     background-color: #fff; 
    cursor: not-allowed;
}
.main-box .react-datepicker-wrapper, .main-box .react-datepicker__input-container,
.main-box .react-datepicker__input-container input{
  display: block;
  width: 98%;
  padding: 0;
}
.main-box .react-datepicker__input-container input{
  min-height: 30px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid hsl(0,0%,80%);
}
.main-box .react-datepicker__input-container input {
    min-height: 32px !important;
    padding: 0 10px !important;
    max-width: 150px;
}