.header-select .ag-header-viewport,
.header-select .ag-header-row,
.header-select .ag-header-cell,
.header-select .ag-header {
  overflow: visible;
}
.header-select .ag-header {
  z-index: 1;
}
.header-select .react-select__control {
  height: 25px;
  min-height: auto;
  position: relative;
  border-color: #95a5a6;
  top: 3px;
}
.header-select .react-select__menu {
  font-size: 12px;
  text-align: left;
}
.header-select .react-select__value-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.header-select .react-select__input {
  width: 100%;
  height: 100%;
  padding: 0;
}
.header-select .react-select__indicator {
  padding: 0;
  position: absolute;
  right: 0;
}
.header-select .react-select__indicator-separator {
  position: absolute;
  right: 20px;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}
.header-select .react-select__option {
  padding: 8px;
}
.header-select .ag-floating-filter-input {
  height: 25px;
  padding: 2px 3px;
  font-size: 12px;
}
.header-select .gridSelectFilter {
  width: 95%;
  height: 25px;
  padding: 2px 3px;
  font-size: 12px;
  margin-top: 3px;
  background: #fff;
}
.header-select .css-b8ldur-Input {
  padding: 0;
}
