.uploadFormWrap {
  display: flex;
  align-items: center;
  max-width: 200px;
}
.uploadFormWrap input {
  border: 1px solid #d0d0d0;
  width: 100%;
  border-radius: 6px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
