.refundPopup{
    width: 450px !important;
}
.refundPopup form{
    width: 100%;
}
.refundPopup .react-select-container{
    width: 100%;
}
.refundPopup .datepicker_custom input{
    min-width: 100%;
}
.refundPopup input{
    width: 100%;
}