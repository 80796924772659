.adminPopup {
    background-color: #fff;
    width: 450px;
    display: inline-table;
}
.adminPopupHeader {
    font-size: 22px;
    font-weight: 600;
    margin-top: -10px;
}
.adminPopupText {
    height: 40px;
    margin-top: -2px;
    padding: 0px 10px;
    display: inline !important;
}

.modalButton {
    margin-top: 20px;
    align-items: center;
}
