.outboundBlock{
    /* padding: 0 15px; */
    margin-bottom: 75px;
}
.mainWrapper {
    margin-left: -104px !important;
    width: auto;
}
.dispositionDetailsWrapper {
    margin-top: 15px;
}
.dispositionDetailsWrapper :global .disposition-block {
    padding-top: 20px;
}
.dispositionDetailsWrapper :global .disposition-block .title {
    font-size: 15px;
    text-transform: uppercase;
    color: #2e5bff;
    letter-spacing: 1.5px;
    font-weight: bold;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
}
.buttonWrapper button{
    margin-right: 10px;
    height: 38px;
    font-size: 12px;
    letter-spacing: normal;
}
.buttonWrapper button:last-child {
    margin-right: 0;
}
.buttonWrapper button:hover, .buttonWrapper button:active, .buttonWrapper button:focus {
    height: 38px;
    font-weight: bold;
    letter-spacing: normal;
}