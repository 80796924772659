.rcTracker ul{
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}

.rcTracker ul a{
    padding-left: 8px;
}
.btn-custom, .btn-custom:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    min-width: 100px;
    font-weight: bold;
}
.Filter_popUP{
    width: 100%;
    background: #fff;
    padding: 20px;
    min-width: 300px;
    padding: 40px 20px;
    border-radius: 10px;
    font-size: 14px;
}
/* .Filter_popUP input{
    margin-top: 20px;
} */
.close{
    position: absolute;
    right: 10px;
    top: 10px;
}
.rc-grid {
    margin-top: 50px;
}