/* .main_section_buyer{
  width: 100%;
  background: #ffffff;
  box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height: 430px;
} */
h2 {
  font-size: 18px;
}
.seller-box {
  margin: 8px 0;
}
.seller-box .lbl {
  font-size: 12px;
  text-align: left;
  margin: 0;
  min-width: 120px;
  line-height: 3;
}
/* .seller-box .txtbox{
  height: 30px;
  background: #ffffff;
  padding: 0 8px;
  font-size: 14px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e0e7ff;
} */
.seller-box .react-datepicker-wrapper,
.seller-box .react-datepicker__input-container,
.seller-box .react-datepicker__input-container input {
  display: block;
  /* width: 100%;
  padding: 0; */
}
.seller-box .react-datepicker__input-container input {
  min-height: 30px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid hsl(0, 0%, 80%);
}
.img-refresh {
  cursor: default;
  height: 18px;
  width: 24px;
}

/* input boxes starts*/

* {
  box-sizing: border-box;
  outline: none;
}
/*body{margin-top:40px}*/
.group {
  position: relative;
  margin-bottom: 23px;
  /* min-width: 240px;  */
  width: 100%;
}
.group input {
  font-size: 14px !important;
  padding: 10px 14px;
  display: block;
  width: 100%;
  border: 1px solid #e0e7ff;
  box-shadow: none !important;
  height: 40px;
  letter-spacing: 0.5px;
  color: #2e384d !important;
  /* max-width: 240px !important; */
}

/* LABEL ======================================= */
.group .label {
  color: green;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.group input:focus ~ .label,
.group input:valid ~ .label,
.group
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input
  :disabled
  ~ .label {
  top: -10px;
  font-size: 11px;
  color: #b0bac9;
  background: #fff;
  padding: 1px 8px;
  left: 6px;
  text-transform: uppercase;
}

/* .error{
  border-bottom: 1px solid red !important; 
} */
.group .error ~ .bar:before,
.group .error ~ .bar:after {
  background: red;
}
.group input.error:focus ~ label,
.group input.error:focus ~ label {
  color: red;
}
.group input.error ~ label,
.group input.error ~ label {
  color: red !important;
}

/* BOTTOM BARS ================================= */
.group .bar {
  position: relative;
  display: block;
  width: 300px;
}
.group .bar:before,
.group .bar:after {
  content: '';
  height: 1px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.group .bar:before {
  left: 50%;
}
.main_section_buyer .text-error {
  padding: 0 10px;
}
.group .bar:after {
  right: 50%;
}

/* active state */
.group input:focus ~ .bar:before,
.group input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.group .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.group input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #efefef;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #efefef;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #efefef;
  }
  to {
    width: 0;
    background: transparent;
  }
}
/* input boxes ends*/

/* date picker starts */
.group .picker {
  font-size: 14px !important;
  padding: 10px 14px;
  display: block;
  /* width: 240px; */
  border: 1px solid #e0e7ff;
  max-width: 100% !important;
  box-shadow: none !important;
  height: 40px;
  letter-spacing: 0.5px;
  color: #2e384d !important;
}
.group .picker:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
.group input:focus ~ .label,
.group input:valid ~ .label,
.group input:disabled ~ .label,
.react-datepicker-wrapper ~ .label {
  top: -10px;
  font-size: 11px;
  color: #b0bac9;
  background: #fff;
  padding: 1px 8px;
  left: 6px;
  text-transform: uppercase;
}
.group .picker:focus ~ .bar:before,
.group .picker:focus ~ .bar:after {
  width: 50%;
}
.group .picker.error:focus ~ label,
.group .picker.error:focus ~ label {
  color: red;
}
.group .picker.error ~ label,
.group .picker.error ~ label {
  color: red !important;
}
/* date picker ends */

/* select start */
.select {
  position: relative;
  margin-bottom: 23px;
  width: 100%;
  min-height: 40px;
}
.buyer-city {
  margin-bottom: 0;
}
.select .css-bg1rzq-control {
  border: 1px solid #e0e7ff;
  max-width: 100% !important;
  height: 40px;
}
/* .css-1szy77t-control:hover, .css-1szy77t-control{
  border: 1px solid #e0e7ff !important;
  box-shadow: none !important;
  min-height: 40px !important;
} */

.select .css-1pcexqc-container ~ .label {
  top: -10px;
  font-size: 11px;
  color: #b0bac9;
  background: #fff;
  padding: 1px 8px;
  left: 6px;
  text-transform: uppercase;
  position: absolute;
}
/* HIGHLIGHTER ================================== */
.css-1hwfws3 {
  font-size: 14px;
}
/* message icon for buyers details starts*/
.message {
  font-size: 12px;
  color: gray;
  display: flex;
  padding: 2px 0 0 0;
  justify-content: flex-end;
  float: right;
  cursor: pointer;
}
.message label {
  max-width: 20px;
  margin: 0 0 0 3px;
}
.message label img {
  width: 100%;
}
.error-message {
  color: red;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  text-align: center;
}
.success-message {
  color: #28a745;
  font-size: 12px;
  width: 100%;
  display: inline-block;
  text-align: center;
}

.pb20 {
  padding-bottom: 20px;
}
/* message icon for buyers details ends*/

.shareLink {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}