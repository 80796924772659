.input-error {
  border: 1px solid #c80039 !important;
}

.inputShift {
  position: relative;
  height: 48px;
}
.inputShift input {
  padding: 0 24px;
  border: 1px solid #dde5eb;
  height: 48px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: transparent;
  color: #465166 !important;
  font-weight: 500;
  box-shadow: none;
}
.inputShift input:focus {
  outline: none;
  box-shadow: none;
  background: #fff;
  border: 2px solid #ed6a00;
}
.inputShift label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 46px;
  color: #9aa1b5;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-size: 16px;
  padding: 0 24px;
  z-index: 0;
}
.inputShift label:after {
  position: absolute;
  content: '';
  width: calc(100% - 30px);
  height: 14px;
  background: #fff;
  display: none;
  left: 15px;
  top: 0px;
  z-index: -1;
}
.inputShift label.active,
.inputShift input:focus ~ label {
  padding: 0 24px;
  font-size: 12px;
  top: -7px;
  line-height: 13px;
  height: 14px;
  width: auto;
  border-radius: 0;
  z-index: 1;
  color: #9aa1b5;
}
.inputShift input:focus ~ label {
  color: #ed6a00;
}
.inputShift label.active:after,
.inputShift input:focus ~ label:after {
  display: block;
}
.error {
  color: #c80039;
  font-size: 12px !important;
  line-height: 20px;
  display: block;
  text-align: right;
}
.textError {
  color: #c80039;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */

/* input[type=number] {
  -moz-appearance: textfield;
} */
