.welcomeWrapper {
    
}
.loadingWrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cfd9cf52;
    display: flex;
    align-items: center;
    justify-content: center;
}
:global .welcomeSubmitCta button, :global .welcomeSubmitCta button:hover{
    height: 38px;
}

.leadUpload{
    margin-top: 15em;
}
