.remarksLog tbody{
    display: block;
    max-height: 240px;
    overflow: auto;
}
.remarksLog thead tr{
    display: flex;
}
.remarksLog thead tr th{
    flex: 1;
}
.remarksLog tbody tr{
    display: flex;
    table-layout: fixed;
    width: 100%;
}
.word-break{
    word-break: break-word;
}