.remarksLog tbody {
  display: block;
  max-height: 240px;
  overflow: auto;
}
.remarksLog thead tr {
  display: flex;
}
.remarksLog thead tr th {
  flex: 1;
}
.remarksLog tbody tr {
  display: flex;
  table-layout: fixed;
  width: 100%;
}
.publish-log td,
.publish-log th {
  font-size: 12px;
  padding: 10px 2px;
}
.publish-log {
  word-break: break-word;
}
.cancel-refund button {
  font-size: 12px;
  padding: 2px 8px;
}
.debit {
  color: darkred;
}
.credit {
  color: green;
}
.debit::before {
  content: '- ₹';
}
.credit::before {
  content: '+ ₹';
}
