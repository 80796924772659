.upload {
  margin:20px 0;
}
.upload h2{
  font-weight: bold;
  font-size: 20px;
  color: #666;
  margin:10px 0 20px 0;
}
.uploadSection {
  background-color:#fff;
  border:1px solid #999;
  border-radius: 5px; 
  display: flex;
  align-items: center;
  padding: 15px;
}
.uploadFile input[type="file"]{
  /* border-radius: 5px; */
  background-color: #fff;
  /* width: 31.6%; */
  cursor: pointer;
}

.uploadFile span {
  font-size: 13px; 
  color:red;
}
.uploadSection .uploadBtn {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  flex: 1 0 auto;
}
.select-allocation span {
  font-size: 13px; 
  color:red;
}
.elect-publish span {
  font-size: 13px; 
  color:red;
}