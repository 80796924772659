/*.seller_section{
   width: 100%;
  background: #ffffff;
  box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height:430px; 
}*/
.seller-box{
  margin:8px 0;
}
h2{
  font-size: 18px;
}
.seller-box .lbl{
  font-size: 12px;
  text-align: left;
  margin: 0;
}
/*.seller-box .txtbox{
   height: 30px;
  background: #ffffff;
  padding: 0 8px;
  font-size: 14px; 
}*/
.react-datepicker-popper {
  left: -70px !important;
  width: auto;
}
.datepicker-container {
  display: flex !important;
  width: 100% !important;
}
.react-datepicker__month-container {
  width: 100% !important;
}
.datepicker-container .react-datepicker__triangle {
  left: 100px;
}
.seller-box :disabled{
  /* background-color: #dfdfdf !important; */
  background-color: #fff !important;
  cursor: not-allowed;
}
.react-datepicker__month-container {
  max-height: 260px;
}
.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-evenly !important;
}
.react-datepicker__month {
  width: 95% !important;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-evenly;
}