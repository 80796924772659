.seller-payment label {
  margin: 0;
}
.custom-button {
  border-radius: 0;
}
.transaction-log ul {
  display: grid;
  grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
  font-size: 14px;
}
.transaction-log ul:first-child {
  font-weight: bold;
  margin-bottom: 30px;
}
.transaction-log ul button {
  font-size: 12px;
  margin-bottom: 30px;
}
.mt30 {
  margin-top: 30px;
}
.full-grid {
  width: 100%;
}

