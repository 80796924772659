.fullscreen_loader {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100000;
  background-color: #9696964d;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}