.main_section_disp{
  /* width: 100%;
  background: #ffffff;
  box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height: auto !important; */
  height: auto;
}
.disp-box{
  margin:8px 0;
}
h2{
  font-size: 18px;
}
.disp-box .lbl{
  font-size: 12px;
  text-align: left;
  margin: 0;
}
.disp-box .txt{
  height: 30px;
  background: #ffffff;
  padding: 0 8px;
  font-size: 14px;
}
.disp-box .textarea{
  height: 70px;
  background: #ffffff;
  padding: 10px 14px;
  font-size: 14px;
  resize: none;
}
.submit, .submit:hover, .submit:focus, .submit:active{
  width: 100%;
    font-weight: bold;
    letter-spacing: 1.5px;
    border: none !important;
    background-color: #2e5bff !important;
    height: 48px;
    box-shadow: none !important;
}
.text-error{
  color: red;
}
.error{
  border-color: red !important;
}

.error .react-select__control{
  border-color: red;
}
.disp-box .react-datepicker-wrapper, .disp-box .react-datepicker__input-container,
.disp-box .react-datepicker__input-container input{
  display: block;
  width: 100%;
}
.disp-box .react-datepicker__input-container input{
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid #e0e7ff;
    max-height: 40px;
  /* min-height: 38px;
  border-radius: 4px;
  border: 1px solid hsl(0,0%,80%); */
}
.button-align{
  text-align: center;
}
.adtnl-flds-dt{
  padding: 2px 8px;
}
/* .disposition-text{
  font-size: 12px;
} */
.disp-box input, .adtnl-flds-dt, .disp-box textarea {
  min-height: 40px;
  font-size: 14px;
  padding: 10px 14px;
  width: 100%;
  border: 1px solid #e0e7ff;
  box-shadow: none !important;
  letter-spacing: 0.5px;
  color: #2e384d !important;
  border-radius: 3px;
}

.disp-box textarea.textAreaError {
  border: 1px solid #c80039;;
}

.css-1g6gooi input, .css-vj8t7z{
  min-height: auto;
  /* border-color: #e0e7ff !important; */
}
.form-control:disabled {
  background-color: #ffffff;
  cursor: not-allowed;
}
.disposition-btn{
  margin-bottom: 0px !important;
  margin-top: 12px !important;
}
.disposition-block{
  padding: 34px 35px 20px 35px;
}
.price-text{
  font-size: 10px;
  text-transform: capitalize;
}
.datetime-picker .react-datepicker-popper{
  left: -150px !important;
}
.datetime-picker .react-datepicker-popper .react-datepicker__triangle{
  left: 190px;
}
.disposition_radio input{
  min-height: 20px;
  width: 20px;
  margin: 3px 0;
  float: left;
}
.disposition-block .react-select-container{
  width: 100%;
}
.disposition-block .react-select-container .react-select__menu .react-select__option{
  line-height: 1.5;
  color: #2e384d;
  text-align: left;
}
.dispositioncheckbox {
  display: flex;
}
.dispositioncheckbox .inputRadioButton {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.dispositioncheckbox .inputRadioButton input, .inputRadioButton input {
  width: auto;
}
.dispositioncheckbox .inputRadioButton label {
  font-size: 12px;
  display: block;
  margin: 0 30px 0 7px;
}
.selectTags :global .css-yk16xz-control, .selectTags :global .css-g1d714-ValueContainer {
  height: auto !important;
}