.paymentStatus td {
  min-width: 300px;
}

.radioFlex div {
  display: flex;
}

.checkbox {
  top: 6px;
  position: relative;
  margin-right: 4px;
}
