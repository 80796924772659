.customError {
  color: red;
  margin-top: 10px;
  font-size: 12px;
}
.remarks {
  margin-top: 10px;
}
.remarks textarea {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-height: 80px;
  padding: 12px;
  width: 100%;
}