.tree{
    padding: 0 10px;
    line-height: 30px;
    color: #fff;
  }
  .tree span{
    display: block;
    margin: 15px 0;
    color: #fff;
  }
  .tree li {
    padding: 0 10px;
  }
  .dealer {
    display: block;
  }
  .dealer a{
    color: #fff !important;
  }  