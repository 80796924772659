.btnRef {
  float: right;
  margin-bottom: 27px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.uploadSectionInsurance {
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 8px;
}
.datepicker_custom input {
  min-width: 100% !important;
}