/* .nav-wrap .bg-light{
    background-color: #f4f6fc !important;
}
.main-box .txtbox{
    height: 40px !important;
    padding: 0 10px !important;
}
.nav-item{
    padding: 0 25px !important;
} */
.nav-wrap .bg-light{
    background-color: #f4f6fc !important;
    border-bottom: 1px solid #fff;
}
/* .main-box .txtbox{
    height: 40px !important;
    padding: 0 10px !important; 
}*/
.admin{
    font-size: 14px;
    display: inline-block;
    color: #9b9b9b;
    font-weight: 100;
}
.dropdown-toggle::after {
    border-top: 0.3em solid rgba(158, 168, 184, .45) !important;
    top: 2px;
    position: relative;
    font-weight: 100;
}
.agent-login span{
    font-weight: normal;
}
.agent{
    background-color: #e0e7ff;
    border-radius: 3px;
    padding: 13px 26px;
}
.agent a, .agent a:hover{
    font-weight: bold;
    font-size: 14px;
    color: #9b9b9b !important;
    line-height: 1;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding: 0;
}
.agent span{
    font-weight: normal;
}
li.dropdown.nav-item a{
    padding-left: 31px !important;
}
.img-cursor-dflt {
    cursor: default;
}