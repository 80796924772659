.toggle-container {
  width: 96px;
  height: 34px;
  border-radius: 9999px;
}
.toggle-btn {
  width: 3rem;
  border-radius: 9999px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  z-index: 10;
}