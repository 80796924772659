.react-select__control--is-disabled {
  background-color: #e9ecef !important;
  border-color: #ced4db !important;
}
.react-select__control--is-disabled .react-select__placeholder {
  color: #495057;
}
.texterror {
  color: #c80039;
}
.selectError {
  color: #c80039;
  display: block;
  /* text-align: right; */
  font-size: 12px;
}

.css-yk16xz-control, .css-g1d714-ValueContainer {
  height: 40px;
}
.css-g1d714-ValueContainer {
  overflow: inherit !important;
}
.css-yk16xz-control {
  flex-wrap: inherit !important;
}
.css-g1d714-ValueContainer {
  width: 80%;
}