@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #f4f6fc;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title {
  font-size: 12px;
  text-transform: uppercase;
  color: #b0bac9;
  letter-spacing: 1.5px;
  margin-bottom: 33px;
  font-weight: normal;
}
.title i img {
  float: right;
  background: #e0e7ff;
  padding: 10px;
  margin-top: -15px;
  border-radius: 3px;
  cursor: pointer;
}
.details-head {
  margin-bottom: 30px;
  width: 100%;
  margin-top: 25px;
  font-size: 14px;
}
.block {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  padding: 34px 35px; /*from 34px 30px*/
  border-radius: 5px;
  /* min-height: 370px; */
  border: solid 1px rgba(46, 91, 255, 0.08);
  height: auto;
}
.text-error {
  color: red;
  font-size: 12px;
}
.main-box .txtbox,
.seller-box .txtbox {
  font-size: 12px;
  font-weight: normal;
  /* max-width: 150px; */
}
.no-results {
  width: 100%;
  text-align: center;
  top: 100vh;
}
.no-results img {
  position: relative;
  top: 30vh;
}
.welcome,
.thanks,
.errorpage {
  color: #b0bac9;
  font-weight: 300;
  font-size: 32px;
  position: relative;
  top: 30vh;
}
.thanks h2,
.errorpage h2 {
  font-size: 32px;
  margin-top: 3vh;
}
.loader {
  position: relative;
  top: 35vh;
}
.css-1hwfws3,
.css-1hwfws3 option {
  font-size: 12px;
}
.always-show > option {
  display: block;
}
.css-xp4uvy,
.css-xp4uvy {
  font-size: 14px;
}

.form-control:focus {
  border-color: #e0e7ff !important;
}
.error.form-control:focus {
  border-color: red !important;
}

.input-filename-hidden {
  color: transparent;
}
.inputWrapperSign > div {
  position: relative;
}
.inputWrapperSign > div::before {
  content: 'Rs.';
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 0;
  margin: auto;
  z-index: 1;
  color: #2e384d;
  font-size: 14px;
  z-index: 0;
}
.inputWrapperSign input {
  padding-left: 35px !important;
}

.mt-neg-lg {
  margin-top: -100px;
}

.documentsConfigAdd {
  height: 120px;
}

.documentsConfigAdd .ag-center-cols-container > div, 
.documentsConfigList .ag-center-cols-container > div {
  padding-top: 6px;
}

.dreMultiselect__control {
  overflow: scroll !important;
}

.dreMultiselect__multi-value__label {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.caseTypesConfigAdd {
  height: 120px;
}

.caseTypesConfigAdd .ag-center-cols-container > div, 
.caseTypesConfigAdd .ag-center-cols-container > div {
  padding-top: 6px;
}