.header{
    padding: 0 0 20px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #dee2e6;
    display: block;
}
.header img{
    float: right;
    margin-right: 20px;
}
.buddyWrapper{
    display: block;
}
.buddyWrapper input{
    width: 250px;
}
.accordionHide{
    display: none;
}
.accordionShow{
    display: block;
}
.buddyscoring h2{
    display: block;
    cursor: pointer;
}
.buddyscoring{
    display: block;
}