.custom-card{
    min-width: 200px;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    margin-right: 30px;
}
.bg-sky-blue{
    background-color: #26C6DA;
}
.bg-blue{
    background-color: #389af0;
}
.bg-purple{
    background-color: #7460ee;
}
.bg-peach{
    background-color: #fc4b6c;
}
.custom-card h4{ 
    font-size: 15px;
    font-weight: 700;
    margin: 0 0 10px;
    white-space: nowrap;
    color: #fff;
}
.custom-card p{ 
    font-size: 28px;
    color: #fff;
}