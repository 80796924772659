.discardProof .react-select-container {
  margin-bottom: 20px;
}
.discardProof form{
  width: 100%;
}
.discardProof textarea{
  resize: none;
}
.proofError {
  margin: 10px;
  float: right;
  color: #c80039;
}

.ml8 {
  margin-left: 8px;
}
