.sliderWrapper {
    position: absolute;
    margin-left: 0px;
    margin-top: 13px;
    z-index: 2;
    width: 16px;
    height: 16px;
    border: 0px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    background-image: linear-gradient(225deg, rgb(246, 155, 0), rgb(237, 106, 0));
    color: #333;
    border: 4px solid #fff;
}
.sliderValue {
    position: absolute;
    top: 0px;
    right: -140%;
    bottom: 0;
    margin: auto;
    height: 40px;
    display: flex;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    padding: 10px 14px;
    width: 100%;
    border: 1px solid #e0e7ff;
    box-shadow: none !important;
    letter-spacing: 0.5px;
    color: #2e384d !important;
    border-radius: 3px;
}
span.min, span.max {
    font-size: 11px;
    color: #666;
    font-style: italic;
}
.sliderWrappermain {
    margin: 0 40px;
}