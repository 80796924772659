.inputWrapper div{
    width: 100%;
}
.inputWrapper button {
    margin-right: 10px;
}
.selectWrapper{
    width: 100%;
}
.selectWrapper :global .react-select-container{
    width: 100%;
}
.inputWrapper div input[type="file"]{
    padding: 5px;
}
.textareWrapper{
    width: 100%;
}
.textareWrapper textarea{
    resize: none;
    /* margin-left: -29px  ; */
    border: 1px solid #dfe8ff;
}
.title{
    font-size: 15px;
    text-transform: uppercase;
    color: #2e5bff;
    letter-spacing: 1.5px;
    font-weight: bold;
    background: #f3f6fb;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
}
.checkboxLabel{
    font-size: 12px;
    display: flex;
    align-items: center;
    /* margin-left: 30px; */
    font-weight: bold;
}
.checkboxLabel input[type="checkbox"]{
    margin-right: 15px;
}

.selectWrapper :global(.react-select__option) {
    line-height: 1.5;
    color: #2e384d;
    text-align: left;
  }
  .orderSummaryWrapper {
    background: #ffffff;
    box-shadow: 0 10px 20px 0 rgb(46 91 255 / 7%);
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px rgba(46, 91, 255, 0.08);
    margin-top: -10px;
    padding: 10px 0 0 0px;
  }
  .finalAmount, .servicesAmount, .finalAmountHeader, .payAble{
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 0 15px;
  }
  .finalAmount p, .finalAmount span, .servicesAmount p, .servicesAmount span, .finalAmountHeader p, .finalAmountHeader span, .payAble p, .payAble span {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin: 0;
    padding: 0;
  }
  .finalAmount span, .servicesAmount span, .finalAmountHeader span, .payAble span, .priceList ul li span {
      width: 110px;
  }
  .finalAmountHeader p, .finalAmountHeader span, .payAble p, .payAble span {
    color: #0059a3;
    font-weight: 600;
    font-size: 16px;
  }
  .finalAmountHeaderWrapper.active .finalAmountHeaderContent{
    display: block;
  }
  .finalAmountHeaderWrapper.active .arrowWrapper img {
    transform: rotate(180deg);
  }
  .finalAmountHeaderContent {
      display: none;
  }
  .finalAmount, .payAble {
      border-top: 1px solid #eae8e8;
      padding: 10px 10px 10px 15px;
  }
  .text {
      color: #212529;
      font-size: 12px;
      margin: 0;
      padding: 6px 10px 10px 15px;
  }
  .priceList {
      padding: 0 0px;
      margin-bottom: 15px;
  }
  .priceList ul {
      margin: 5px 0 0 15px;
      padding:  0 10px;
  }
  .priceList ul li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 4px 0;
  }
  .priceList ul li p, .priceList ul li span {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #212529;
  }
.arrowWrapper {
    display: flex;
}
.arrowWrapper img {
    margin-left: 10px;
    cursor: pointer;
}
.deliveryDetails {
    height: 120px;
    overflow: auto;
    border: 1px solid hsl(0,0%,80%);
    padding: 10px;
    width: 100%;
    margin: 15px;
    border-radius: 3px;
}
.deliveryDetails ul {
    margin: 0;
    padding: 0;
}
.deliveryDetails ul li {
    list-style: none;
    margin: 0;
    border: 1px solid #ebf4f9;
    padding: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 3px;
}
.deliveryDetails ul li p {
    margin: 0;
    padding: 0;
    color: #465166;
    line-height: 16px;
    font-size: 14px;
}
.deliveryDetails ul li p.address {
    color: rgb(70 81 102/.4);
    font-size: 12px;
    line-height: 18px;
    padding-top: 5px;
}
.deliveryDetails ul li.active {
    border-color: #007bff;
}

.deliveryDetails ul li p.pickup {
    font-size: 12px;
    color: #465166;
    width: 200px;
    padding-left: 20px;
}
.deliveryDetails ul li p.pickup span {
    font-weight: 700;
    color: #18b770;
}
.errortext p, .errortext, .error {
    color: red;
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.errortext {
    padding: 10px 0 10px 15px;
}
.error {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 14px;
}
.leadInteractionHistory {
    width: 99%;
}

.leadInteractionHistory table thead tr th {
    padding: 0px 10px 10px 0;
    font-size: 14px;
}
.leadInteractionHistory table {
    border-collapse:collapse;
    table-layout:fixed;
}
.leadInteractionHistory table th, .leadInteractionHistory table td  {
    word-wrap:break-word;
}
.leadInteractionHistory table tbody tr td {
    font-size: 14px;
    padding: 10px 10px 10px 0;
}
.leadInteractionHistory table tbody tr td ul {
    margin: 0;
    padding: 0;
    list-style-type: decimal;
}
.leadInteractionHistory table tbody tr td:first-child, .leadInteractionHistory table thead tr th:first-child  {
    padding-left: 10px;
}
.leadInteractionHistory table tbody tr:nth-child(odd) {
    background-color: #e6e6e6;
}
.leadInteractionTable {
    padding: 10px;
    border:1px solid #e6e6e6;
}
.dataList {
    margin-bottom: 20px;
    margin-top: 20px;
}
.dataList  ul {
    margin: 0;
    padding: 0;
    display: flex;
}
.dataList ul li {
    list-style: none;
    color: #666;
    margin: 0 60px 0 0;
}
.dataList ul li p {
    margin: 0;
    padding: 0px 0 5px 0;
    font-size: 14px;
    font-weight: 500;
    color: #2e5bff;
}
.dataList ul li span {
    font-size: 13px;
}
