.inputSpace {
  margin-bottom: 20px;
}
.ml10 {
  margin-left: 10px;
}
.mt20 {
  margin-top: 20px;
}
.borderWrapper {
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 8px;
}
.borderWrapper h2 {
  justify-content: space-between;
  display: flex !important;
  margin: 0;
  padding: 0px 0 15px 0;
}
.mt30 {
  margin-top: 30px;
}
.p0{
  padding: 0 !important;
}
.p15{
  padding: 15px !important;
}
.top-border{
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
}
.fs12{
  font-size: 12px;
}

.fwb{
  font-weight: bold;
}
.m0{
  margin: 0;
}