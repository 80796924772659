.systemWrapper{
    display: block;
}
.systemWrapper h2{
    display: block;
    cursor: pointer;
}

.systemHeader{
    padding: 0 0 20px 0;
    margin: 0 0 10px 0;
    border: none;
    display: block;
}
.systemHeader img{
    float: right;
    margin-right: 20px;
}
.accordionHide{
    display: none;
}
.accordionShow{
    display: block;
}
.boderBottom{
    border-bottom: 1px solid #dee2e6;
}