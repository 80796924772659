.cAccordianWrapper {
  display: block;
  padding: 0%;
}

.cAccordianWrapper li{
  list-style-type: none;
}

.borderWrapper {
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 8px;
}

.borderWrapper h2 {
  justify-content: space-between;
  display: flex !important;
  margin: 0;
}