.radioRcDocument{
    position: static !important;
    margin-right: 10px;
}
.inputRadioWrap{
    display: flex;
    margin-bottom: 12px;
}
.rcDocuments{
    width: 100%;
}
.rcDocumentradioBox{
    min-width: 410px;
}
.rcDocumentradioBox .inputRadioButton{
    width: 33%;
}
.buyDocumentRadio{
    flex-basis: 245px;
    min-width: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.radioHandle div{
    display: flex;
}