
.tabsDataWrappr {
    background-color: #fff;
    margin-top: -1px;
    border: 1px solid #cecaca;
    min-height: 300px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 0 15px 50px 24px;
}
.title{
    font-size: 15px;
    text-transform: uppercase;
    color: #2e5bff;
    letter-spacing: 1.5px;
    font-weight: bold;
    background: #f3f6fb;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}
.dataWrapper {
    margin-top: 10px;
}
.dataWrapper ul {
    margin: 0;
    padding: 0;
    display: flex;
}
.dataWrapper ul li {
    list-style: none;
    display: flex;
    font-size: 12px;
    color: #212529;
    margin: 8px 0;
}

.dataList {
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
}
.dataList  ul {
    margin: 0;
    padding: 0;
    display: flex;
}
.dataList ul li {
    list-style: none;
    color: #666;
    margin: 0 50px 0 0;
}
.dataList ul li:first-child {
    width: auto
}
.dataList ul li p {
    margin: 0;
    padding: 0px 0 5px 0;
    font-size: 14px;
    font-weight: 500;
    color: #2e5bff;
}
.dataList ul li span {
    font-size: 13px;
}
.fixedWidth, .fixedWidth li {
    width: 250px !important;
}

.documentViewSection, .documentViewCta  {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 20px 0;
}
.documentViewSection button, .documentViewCta button {
    margin: 10px 13px 0 0;
    height: 36px;
    font-size: 12px;
    width: 30%;
}
.documentViewSection button:hover, .documentViewSection button:focus, .documentViewSection button:active,  .documentViewCta button:hover, .documentViewCta button:focus, .documentViewCta button:active  {
    width: 30%;
    height: 36px;
}
.leadInteractionHistory {
    width: 99%;
}

.leadInteractionHistory table thead tr th {
    padding: 0px 10px 10px 0;
    font-size: 14px;
}
.leadInteractionHistory table {
    border-collapse:collapse;
    table-layout:fixed;
}
.leadInteractionHistory table th, .leadInteractionHistory table td  {
    word-wrap:break-word;
}
.leadInteractionHistory table tbody tr td {
    font-size: 14px;
    padding: 10px 10px 10px 0;
}
.leadInteractionHistory table tbody tr td ul {
    margin: 0;
    padding: 0;
    list-style-type: decimal;
}
.leadInteractionHistory table tbody tr td:first-child, .leadInteractionHistory table thead tr th:first-child  {
    padding-left: 10px;
}
.leadInteractionHistory table tbody tr:nth-child(odd) {
    background-color: #e6e6e6;
}
.leadInteractionTable {
    padding: 10px;
    border:1px solid #e6e6e6;
}
.buttonWrapper button{
    margin-right: 10px;
    height: 38px;
    font-size: 12px;
    letter-spacing: normal;
}
.buttonWrapper button:last-child {
    margin-right: 0;
}
.buttonWrapper button:hover, .buttonWrapper button:active, .buttonWrapper button:focus {
    height: 38px;
    font-weight: bold;
    letter-spacing: normal;
}
.dataMainWrapper {
    padding-left: 20px;
}
.selectWrapper :global .react-select-container, :global .css-2b097c-container{
    width: 100%;
}
.cfForm {
    padding-top: 10px;
}
.inputWrapper>div {
    width: 100%;
}
.inputWrapperMultiselect :global .css-yk16xz-control, .inputWrapperMultiselect :global .css-g1d714-ValueContainer {
    min-height: 40px;
    height: 100% !important;
    overflow: auto !important;
}
.inputWrapperMultiselect :global .react-select__menu .css-26l3qy-menu {
    height: 200px !important;
}
.inputWrapperMultiselect :global .react-select__menu-list {
    height: 100px !important;
    overflow: scroll !important;
}
.errortext {
    color: red;
    font-size: 12px;
    margin: 0;
    padding: 5px 0;
}

.deliveryDetails {
    border: 1px solid hsl(0,0%,80%);
    padding: 10px;
    width: 100%;
    margin: 15px;
    border-radius: 3px;
}

.deliveryDetails ul {
    margin: 0 0 20px 0 !important;
    padding: 0 !important;
    display: block !important;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
}
.deliveryDetails ul li {
    list-style: none;
    margin: 0;
    border: 1px solid #ebf4f9;
    padding: 12px !important;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 3px;
}
.deliveryDetails ul li p {
    margin: 0;
    padding: 0;
    color: #465166;
    line-height: 16px;
    font-size: 14px;
}
.deliveryDetails ul li p.address {
    color: rgb(70 81 102/.4);
    font-size: 12px;
    line-height: 18px;
    padding-top: 5px;
}
.deliveryDetails ul li.active {
    border-color: #007bff;
}

.deliveryDetails ul li p.pickup {
    font-size: 12px;
    color: #465166;
    width: 250px;
    padding-left: 20px;
}
.deliveryDetails ul li p.pickup span {
    font-weight: 700;
    color: #18b770;
}
.deliveryDetails ul li p.distance {
    font-size: 12px;
    color: #465166;
    width: 250px;
    padding-left: 20px;
}
.deliveryDetails ul li p.distance span {
    font-weight: 700;
    color: #18b770;
}

.selectedAddres .deliveryDetails{
    margin: 0;
}
.selectedAddres .deliveryDetails ul {
    max-height: initial;
    min-height: auto;
    margin: 0 !important;
}
.selectedAddres .deliveryDetails ul li {
    border: none;
    padding: 0 !important;
    margin: 0 !important;
}

.ordersummary {
    position: sticky;
    top: 10px;
}
.orderSummaryWrapper {
    background: #ffffff;
    box-shadow: 0 10px 20px 0 rgb(46 91 255 / 7%);
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px rgba(46, 91, 255, 0.08);
    margin-top: -10px;
    padding: 10px 0 0 0px;
  }
  .finalAmount, .servicesAmount, .finalAmountHeader, .payAble{
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 0 15px;
  }
  .finalAmount p, .finalAmount span, .servicesAmount p, .servicesAmount span, .finalAmountHeader p, .finalAmountHeader span, .payAble p, .payAble span {
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin: 0;
    padding: 0;
  }
  .finalAmount span, .servicesAmount span, .finalAmountHeader span, .payAble span, .priceList ul li span {
      width: 110px;
  }
  .finalAmountHeader p, .finalAmountHeader span, .payAble p, .payAble span {
    color: #0059a3;
    font-weight: 600;
    font-size: 16px;
  }
  .finalAmountHeaderWrapper.active .finalAmountHeaderContent{
    display: block;
  }
  .finalAmountHeaderWrapper.active .arrowWrapper img {
    transform: rotate(180deg);
  }
  .finalAmountHeaderContent {
      display: none;
  }
  .finalAmount, .payAble {
      border-top: 1px solid #eae8e8;
      padding: 10px 10px 10px 15px;
  }
  .text {
      color: #212529;
      font-size: 12px;
      margin: 0;
      padding: 6px 10px 10px 15px;
  }
  .priceList {
    padding: 0 0px;
    margin-bottom: 15px;
}
.priceList ul {
    margin: 5px 0 0 15px;
    padding:  0 10px;
}
.priceList ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
}
.priceList ul li p, .priceList ul li span {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #212529;
}
.arrowWrapper {
    display: flex;
}
.arrowWrapper img {
    margin-left: 10px;
    cursor: pointer;
}
.checkingEligibilty {
    display: flex;
    align-items: center;  
    margin-top: 10px; 
}
.checkingEligibilty h1 {
    font-size: 14px;
    color: #333;
}
.paymentMode label {
    margin-right: 30px;
}
.radioList ul {
    margin: 0 !important;
}
.radioList ul li {
    margin-right: 20px;
}
.radioList ul li div {
    width: 100%;
    display: flex;
    align-items: center;
}
.repairDetails {
    margin-top: 10px;
}
.repairDetails textarea {
    height: 40px;
}
.repairDetails label {
    margin-right: 40px;
}
.raiseRepairRequestPopup {
    background-color: #fff;
    width: 800px;
    padding: 20px;
    border-radius: 10px;
}
.raiseRepairRequestPopup h2, .preBookingCancelOtpPopup h2{
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.raiseRepairRequestPopup textarea {
    height: 40px;
}
.yes, .viewButton {
    padding-left: 20px;
    color: #2e5bff;
    font-style: 14px;
}
.btnWrapper button {
    margin-right: 20px;
}
.modalWrapper {
    background-color: #ffffff;
    padding: 20px;
    width:400px;
}
.modalButtonWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: -10px;
    margin-right: -10px;
    padding-top: 130px;
}
.modalButtonWrapper button {
    margin: 10px;
}
.errorMessage {
    color: rgb(233, 16, 16);
    font-size: 12px;
}
.preBookingCancelOtpPopup h2 {
    margin: 0px 0 30px 0 ;
}
.preBookingCancelOtpPopup {
    width: 400px;
    min-height: 400px;
}
.preBookingCancelOtpPopup :global .react-select-container{
    margin-bottom: 20px;
}
/* :global .react-select__menu .css-26l3qy-menu {
    min-height: 40px !important;
    max-height: 40px !important;
    overflow: scroll !important;
} */
.preBookingCancelOtpPopup :global .otpSection {
    margin: 20px 0 10px 0;
}
.preBookingCancelOtpPopup .errorMessage {
    display: block;
    margin-bottom: 15px;
}
.raiseRepairRequestPopup :global .react-select__menu .css-26l3qy-menu{
    height: 100px !important;
}
.viewButton {
    margin: 0;
    padding: 0;
    font-weight: 500;
    padding-left: 10px;
    cursor: pointer;
} 
.commentsTabs {
    margin-top: 30px;
}
.commentsTabs  ul {
    margin: 0;
    padding: 0;
    display: flex;
}
.commentsTabs ul li{
    list-style: none;
    background-color: #ececec;
    color: #333;
    font-size: 14px;
    display: flex;
    text-align: center;
    align-items: center;
    height: 36px;
    padding: 0 20px;
    border-top-right-radius: 18px;
    border: 1px solid #cecaca;
    cursor: pointer;
}
.commentsTabs ul li.active {
    background-color: #2e5bff;
    border: 1px solid #2e5bff;
    color: #fff;
}
.commentsWrapper {
    border:1px solid #cecaca;
    padding: 10px;
    font-size: 14px;
    color: #333;
    margin-top: -1px;
}
.resendOtp {
    color:#2e5bff;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    display: block;
    margin-top: -5px;
    cursor: pointer;
}
.checkbox input {
    width: 20px;
}
.textArea textarea[readonly]{
    background-color: #e9ecef;
}
.carCardsWrapper {
    display: grid;
    grid-template-columns: 32% 32% 32%; 
    grid-gap: 20px;
    margin-top: 20px;
}
.carsImage {
    background-color: #c1c1c1;
    position: relative;
    width: 100%;
    height: 202px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.carCard {
    border-radius: 10px;
    -webkit-box-shadow: 0 8px 20px #ebf4f9;
    box-shadow: 0 8px 20px #ebf4f9;
    min-height: 435px;
}
.carCard img {
    max-width: 100%;
    max-height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position :absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
.carCard .carDetails {
    padding: 20px;
}
.carCard .carDetails h2, .carCard .carDetails p {
    margin-bottom: 0;
    font-weight: 700;
    color: #465166;
    font-size: 18px;
    line-height: 28px;
}
.carCard .carDetails p {
    font-size: 14px;
    line-height: 22px;
}
.carCard .carDetails p span{
    font-weight: 300;
}
.carCard .carDetails ul {
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #dde5eb;
}
.carCard .carDetails ul li {
    max-width: 100px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: #ebf4f9;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #465166;
    list-style: none;
}
.carCard .carDetails .priceSection {
    padding-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.monthlyPrice {
    font-size: 20px;
    line-height: 22px;
    color: #ef7e2b;
    font-weight: 700;
}
.price {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #465166;
}
.downPayment {
    color: #979797;
    font-size: 12px;
    line-height: 18px;
}

.wrapperAgentDetails {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    transition: all 0.8s ease-out;
    width: 100%;
}
.tranLogs {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 20px;
    padding-right: 10px;
    transition: all 0.8s ease-out;
    /* width: 100%; */
}
.tranLogs ul, .tranLogs h3, .tranLogs label{
    padding: 0;
    margin: 0;
}
.tranLogs h3{
    line-height: 40px;
    padding: 10px 0;
}
.tranLogs ul {
    margin-left: 0 !important;
    padding: 5px 0 !important;
}

.wrapperAgentDetails ul, .wrapperAgentDetails h3, .wrapperAgentDetails label{
    padding: 0;
    margin: 0;
}
.wrapperAgentDetails h3{
    line-height: 40px;
    padding: 10px 0;
}
.wrapperAgentDetails ul {
    margin-left: 0 !important;
    padding: 5px 0 !important;
}
.history table {
    margin: 0 15px;
}
.history table thead tr th {
    padding: 0 5px 10px 5px;
    font-size: 14px;
    min-width: 100px;
}
.history table tbody tr td {
    padding: 5px 5px;
    font-size: 14px;
    min-width: 100px;
}
.history table tbody tr td span {
    color: #18b770;
}
.selectWrapperBox .documentViewCta {
    margin: 0;
    width: 100%;
}
.selectWrapperBox .documentViewCta button {
    margin: 0 10px 0 0;
    width: 250px;
}
.wordBreak {
    word-break: break-all;
}
.radioFlex {
    display: flex;
}
.radioFlex :global .inputRadioButton {
    margin-right: 20px;
}
.radioFlex :global .inputRadioButton label {
    font-size: 14px;
}
.monthselector :global .inputRadioButton {
    margin-right: 16px;
}
.loanTenure {
    display: flex;
    margin: 0;
    padding: 0;
}
.loanTenure li {
    list-style: none;
}
.checkbox {
    margin-top: 10px;
}
.changeLocation button, .changeLocation button:focus, .changeLocation button:hover {
    height: 40px;
}
.changeLocationPopup {
    width: 400px;
}
.mrauto{margin: auto;}
.checkbox {
    margin-top: 10px;
}

.addCta {
    height: 30px !important;
    width: 30px !important;
    padding: 0 !important;
}

.selectWrapper :global .css-yk16xz-control, .selectWrapper :global .css-g1d714-ValueContainer {
    min-height: 40px;
    height: 100% !important;
    overflow: auto !important;
}
.selectWrapper :global .react-select__menu .css-26l3qy-menu {
    height: 200px !important;
}
.selectWrapper :global .react-select__menu-list {
    height: 200px !important;
    overflow: scroll !important;
}

.visible {
    display: block;
}

.hide {
    display: none;
}
.viewLoan {
    width: 700px;
    height: 450px;
}

.textInfo {
    color: #160808;
    font-size: 8px;
    margin: 0;
}