.fs14 {
  font-size: 14px;
}

.wordBreakAll {
  word-break: break-all;
}

.headingUnderlined {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration: underline;
}

.mb20 {
  margin-bottom: 20px;
}

.mb10 {
  margin-bottom: 10px;
}

.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mr20 {
  margin-right: 20px;
}

.mr10 {
  margin-right: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml10 {
  margin-left: 10px;
}
.textCenter {
  text-align: center;
}

.textSuccess {
  color: green;
}

.textError {
  color: red;
}
