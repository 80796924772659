/*.section_interaction_journey{
   width: 100%; */
  /* background: #ffffff; */
  /* box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0;
    padding: 0; */
  /* height:430px; 
}*/
.section_interaction_journey .count{
  border-radius: 7px;
    border: solid 1px rgba(46, 91, 255, 0.08);
    background-color: #ffffff;
    padding: 16px 0;
    margin-bottom: 10px !important;
}
.journey{
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.6px;
  color: #b0bac9;
  margin-bottom: 30px;
  text-transform: uppercase;
  margin-top: 42px;
}
.section_interaction_journey .lbl{
  font-size: 12px;
  color: #b0bac9;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.td{
  font-size: 14px;
  color: #2e384d;
  line-height: 1.57;
  font-weight: 100;
  letter-spacing: 0.5px;
}