/* 
.head_left{
    width: 20%;
}
.head_left_last{
    width: 30%;
}
.head_left .labl,.head_left_last .labl
{
    font-size: 13px;
    font-weight: bold;
    margin-right: 8px;
}
.head_left .span{
    font-size: 12px;
}
.no-results {
    text-align: center;
    padding-top: 15%;
} */
/*Shilpi Change*/
body{
    background: #f4f6fc;
}
.head_left{
    /* width: 20%; */
    margin-right: 21px;
}
.head_left_last{
    /* width: 30%; */
    margin-right: 21px;
}
.head_left .labl,.head_left_last .labl{
    font-size: 14px;
    margin-right: 8px;
    color: #b0bac9;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 100;
}
.head_left .span, .head_left_last .span{
    color: #0037ff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1.5px;
}
.inspection button, .inspection button:hover{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #0037ff;
    float: right;
    text-decoration: none;
}
.inspection{
    position: absolute;
    right: 20px;
}

.seller-dispositions{
    width: 28%;
    margin-right: 15px;
}
.seller-price-details{
    width: 27%;
    margin: 0 15px;
}
.seller-car-details{
    width: 40%;
    margin-left: 15px;
}
.seller-disposition{
    width:100%;
} 
 .section_interaction_journey .row{
    margin: 0px;
}