.zoomHandler {
  position: absolute;
  z-index: 9;
  bottom: 12px;
  padding: 2px 2px 2px 2px;
  background-color: silver;
  border-radius: 16px;
  opacity: 0.8;
}
.zoomHandler button {
  border: none;
  margin-right: 30px;
  background: rgb(0 0 0 / -0.9);
  border-radius: 16px;
}
.zoomHandler button:focus {
  outline: none;
  /* opacity: 0.5; */
}

.zoomHandler button:hover {
  outline: none;
  opacity: 0.5;
}

.zoomHandler button:last-of-type {
  margin: 0;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.zoomHandler button:active {
  transform: scale(0.9);
  /* Scaling button to 0.98 to its original size */
  /* box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); */
  /* Lowering the shadow */
}
