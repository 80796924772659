.capitalize {
  text-transform: capitalize;
}
.datepicker_custom input {
  min-width: 240px;
  height: 38px;
  border-radius: 3px;
  border: 1px solid #ced4db;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  background-image: url(./date_picker.svg);
  background-size: 8%;
  background-repeat: no-repeat;
  background-position: 95% center;
  width: 100%;
}
.datepicker_custom .react-datepicker__close-icon {
  right: 48px;
}
.datepicker_custom .react-datepicker__close-icon::after {
  background-color: transparent;
  color: #0a0a0a;
  font-size: 25px;
  top: 0;
  position: static;
  line-height: 0;
}
.datepicker_custom .react-datepicker-wrapper {
  width: 100%;
}
.datepicker_custom .react-datepicker__input-container {
  width: 100%;
}
.datepicker_custom .react-datepicker-popper {
  left: auto !important;
}
.datepicker_custom input:disabled {
  background-color: #eaecef;
}
.rc-tracker .heading {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
  text-decoration: underline;
}

.icon-fa {
  width: 20px;
  text-align: center;
  float: right;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
}
.mt80 {
  margin-top: 80px;
}
.fs16 {
  font-size: 16px;
}

.deal-input {
  width: 75%;
  border: none;
  border-bottom: solid 0.5px;
  padding-left: 4px;
  color: #212529;
}
.rc-add-link {
  color: '#0000EE';
  cursor: pointer;
}

.rc-val {
  word-break: break-all;
}
