.inputRadioButton{
    position: relative;
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
}
.inputRadioButton label{
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 12px;
    color: #333;
}