.wrap{
    width: 100%;
    background: #fff;
    padding: 20px;
    min-width: 300px;
    padding: 40px 50px;
}
.wrap h1{
    color: #bbb;
    padding-bottom: 40px;
    font-size: 20px;
}
.wrap .btn {
    width: 100%;
}