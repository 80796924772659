.icon-fa {
  width: 20px;
  text-align: center;
  float: right;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
}

.editable-label-input {
  border: none;
  border-bottom: solid 0.5px;
  color: #212529;
}