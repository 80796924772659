/* .car_container{
  width: 100%;
  background: #ffffff;
  box-shadow:0 10px 20px 0 rgba(46, 91, 255, 0.07);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  height: 430px;
} */
.main-box{
  margin:8px 0;
}
h2{
  font-size: 18px;
}
.main-box .lbl{
  font-size: 12px;
  text-align: left;
  width: 30%;
  margin: 0;
}
/* .main-box .txtbox{
  height: 30px;
  max-width: 70%;
  background: #ffffff;
  margin: 0 0 0 8px;
  padding: 0 8px;
  font-size: 14px;
} */
.main-box .select{
  height: 30px;
  width: 70%;
  background: #ffffff;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  border:1px solid #ced4da;
}
.main-box .radio_label{
  font-size: 12px;
  margin-right: 8px;
  min-width: 62px;
}
.radio_label .radio-btn{
padding-right: 3px;
}
.main-box :disabled{
  /* background-color: #dfdfdf; */
  background-color: #fff !important; 
  cursor: not-allowed;
}
.fuel-select{width: 165px;}
.main-box .react-datepicker__input-container input{
  min-height: 32px !important;
  padding: 0 10px !important;

}