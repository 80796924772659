.rcTable table {
    margin: 0 15px;
}
.rcTable table thead tr th {
    padding: 0 5px 10px 5px;
    font-size: 14px;
    min-width: 100px;
}
.rcTable table tbody tr td {
    padding: 5px 5px;
    font-size: 14px;
    min-width: 100px;
}