.tableWapper {
  width: 100%;
  margin-top: 50px;
}
.tableWapper thead tr td {
  font-weight: 600;
  text-align: center;
  width: 15%;
  font-size: 14px;
}
.tableWapper tbody tr td {
  text-align: center;
  font-size: 12px;
  line-height: 40px;
}
.gstlabel {
  margin: 0;
  line-height: 40px;
  font-size: 16px;
  margin-right: 12px;
}
.tableWapper tbody tr td input {
  text-align: center;
  max-width: 150px;
  margin: auto;
  height: 30px;
  font-size: 12px;
  font-weight: 600;
}
.errorLabel {
  text-align: center;
  line-height: 60px;
  font-size: 12px;
  color: red;
}
.customBtn {
  min-width: 70%;
}
